@import "../../assets/scss/main.scss";

#footer {
  background: $white;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  color: $night-rider;
  font-size: 14px;

  .footer-links a {
    color: $dim-gray;
    padding-left: 15px;

    &:first-child {
      padding-left: 0;
    }

    &:hover {
      color: $second-color;
    }
  }
}
