@import "../../../../assets/scss/main.scss";

#contact {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 0;
  overflow: hidden;

  .contact-about {
    h3 {
      font-size: 36px;
      margin: 0 0 10px 0;
      padding: 0;
      line-height: 1;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: $first-color;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      font-family: "Montserrat", sans-serif;
      color: $grey-2;
    }
  }

  .social-links {
    padding-bottom: 20px;

    a {
      font-size: 18px;
      background: $white;
      color: $first-color;
      padding: 8px 0;
      margin-right: 4px;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      transition: 0.3s;
      border: 1px solid $first-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      i {
        line-height: 0;
      }

      &:hover {
        background: $second-color;
        border: 1px solid $second-color;
        color: $white;
      }
    }
  }

  .info {
    color: $night-rider;

    i {
      font-size: 32px;
      color: $first-color;
      float: left;
      line-height: 0;
    }

    p {
      padding: 0 0 10px 42px;
      line-height: 28px;
      font-size: 14px;
    }
  }

  .email-form {
    .is-invalid {
      border: 1px solid $free-speech-red;
    }

    .error-message {
      color: $free-speech-red;
    }

    .sent-message {
      margin-top: 1rem;
      text-align: center;
    }

    input,
    textarea {
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
    }

    input::focus,
    textarea::focus {
      background-color: $first-color;
    }

    button[type="submit"] {
      background: linear-gradient(-45deg, $first-color, $second-color);
      border: 0;
      border-radius: 20px;
      padding: 8px 30px;
      color: $white;
      margin-top: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
