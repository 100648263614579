@import "../../../../assets/scss/main.scss";

.step {
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
  background: $white;
  padding: 40px;
  width: 100%;
  margin-bottom: 30px;

  .number {
    background: $first-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    letter-spacing: 0.2rem;
    margin-bottom: 10px;
    display: block;
  }

  h3 {
    overflow-wrap: break-word;
  }

  p {
    color: $grey-3;
  }

  p:last-child {
    margin-bottom: 0px;
  }
}
