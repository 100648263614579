@import "../../../../assets/scss/main.scss";

.video {
  margin: auto;
}

@media screen and (min-width: 1200px) {
  .video {
    width: 75%;
  }
}
