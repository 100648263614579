@charset "utf-8";

/*--------------------------------------------------------------
# Import a Google Font
--------------------------------------------------------------*/

@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i);
$font-family-roboto: "Roboto", sans-serif;
$font-family-poppins: "Poppins", sans-serif;

/*--------------------------------------------------------------
# Set your brand colors
--------------------------------------------------------------*/

$white: #fff;
$grey-chateau: #9fa1a4;
$grey-1: #777;
$grey-2: #888;
$grey-3: #595959;
$grey-4: #515e61;
$dim-gray: #666;
$black-1: #000;
$black-2: #111;
$night-rider: #333;
$nobel: #999;
$light-sea-green: #05b2ad;
$free-speech-red: #c80e0e;
$silver: #bababa;
$dark-cyan: #048890;
$orange-1: #f68b1f;
$orange-2: #e68b1f;

/*--------------------------------------------------------------
# Set your brand variables
--------------------------------------------------------------*/

// Font and Colors
$first-font-family: $font-family-roboto;
$second-font-family: $font-family-poppins;
$first-color: $dark-cyan;
$second-color: $light-sea-green;
$first-font-color: $grey-chateau;
$third-color: $orange-1;
