@import "../../assets/scss/main.scss";

.container {
  .about {
    padding: 50px 0px 50px;

    .title {
      font-weight: 300;
      margin-bottom: 15px;
      font-size: 22px;

      span {
        color: $black-2;
      }
    }
  }
}
