@import "../../../../assets/scss/main.scss";

.hero-section {
  background: linear-gradient(
      to right,
      $first-color 0%,
      rgba(61, 179, 197, 0.8) 100%
    ),
    url(../../../../../public/assets/img/hero-bg.jpg);
  position: relative;

  .wave {
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    bottom: -150px;

    svg {
      width: 100%;
    }
  }

  height: 100vh;
  min-height: 880px;

  > .container > .row {
    height: 100vh;
    min-height: 880px;
  }

  &.inner-page {
    height: 60vh;
    min-height: 0;

    .hero-text {
      transform: translateY(-150px);
      margin-top: -120px;
    }
  }

  h1 {
    font-size: 3.5rem;
    color: $white;
    font-weight: 700;
    margin-bottom: 30px;
  }

  p {
    font-size: 18px;
    color: $white;
  }

  .button-container {
    .btn {
      width: 160px;
    }
  }

  .iphone-wrap {
    position: relative;

    .phone-1 {
      width: 150%;
      position: absolute;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 992px) {
  .hero-section {
    .wave {
      bottom: -180px;
    }

    h1 {
      font-size: 2.5rem;
      text-align: center;
      margin-top: 40px;
    }

    .hero-text-image {
      margin-top: 4rem;
    }

    .iphone-wrap {
      text-align: center;

      .phone-1 {
        position: relative;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 350px;
      }
    }
  }

  .hero-section.inner-page .hero-text {
    margin-top: -80px;
  }
}

@media screen and (min-width: 993px) {
  .hero-section {
    .button-container {
      display: flex;
      justify-content: space-between;
      max-width: 340px;
    }

    .iphone-wrap {
      .phone-1 {
        top: -50%;
        left: -25%;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .hero-section .iphone-wrap {
    .phone-1 {
      top: -75%;
      left: -30%;
    }
  }
}

@media screen and (min-width: 1400px) {
  .hero-section .iphone-wrap {
    .phone-1 {
      top: -100%;
      left: -50%;
    }
  }
}
