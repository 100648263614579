@import "../../../../assets/scss/main.scss";

#pricing {
  padding: 60px 0 60px 0;
  overflow: hidden;

  h3 {
    font-weight: 400;
    margin-bottom: 15px;
    font-size: 28px;
  }

  p {
    color: $silver;
    font-size: 20px;
  }

  .box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid transparent;
    padding: 40px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
    background: $white;
    text-align: center;

    &:hover {
      transition: transform 0.3s ease-out;
      border: 2px solid $first-color;
      cursor: pointer;
    }

    h4 {
      font-size: 46px;
      color: $first-color;
      font-weight: 300;
    }

    p {
      font-size: 16px;
    }

    ul {
      padding: 0;
      list-style: none;
      color: $nobel;
      text-align: left;
      line-height: 20px;

      li {
        padding-bottom: 12px;
      }

      i {
        color: $first-color;
        font-size: 18px;
        padding-right: 6px;
      }
    }

    .get-started-btn {
      margin-inline: auto;
      width: 170px;
      display: inline-block;
      padding: 6px 30px;
      border-radius: 20px;
      color: $white;
      transition: none;
      font-size: 14px;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
    }

    .enabled {
      background: linear-gradient(-45deg, $first-color, $second-color);
    }

    .disabled {
      background: $grey-4;
      pointer-events: none;
    }

    &--patient {
      h4,
      ul i {
        color: $third-color;
      }

      .get-started-btn {
        background: linear-gradient(-45deg, $orange-2, $third-color);
      }
    }
  }
}
