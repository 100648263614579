@import "../../../../assets/scss/main.scss";

#call-to-action {
  background: linear-gradient($first-color, $second-color);
  padding: 80px 0;

  .cta-title {
    color: $white;
    font-size: 28px;
    font-weight: 700;
  }

  .cta-text {
    color: $white;
  }

  .cta-btn {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px;
    border-radius: 25px;
    transition: background 0.5s;
    margin: 10px;
    border: 2px solid $white;
    color: $white;

    &:hover {
      background: $second-color;
      border: 2px solid $second-color;
    }
  }
}

@media (min-width: 769px) {
  #call-to-action .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
