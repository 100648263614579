@import "../../assets/scss/main.scss";

#header {
  height: 90px;
  transition: all 0.5s;
  z-index: 997;
  background: linear-gradient(45deg, $first-color, $second-color);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);

  &.header-transparent {
    background: transparent;
  }

  &.header-scrolled,
  &.header-solid {
    background: linear-gradient(45deg, $first-color, $second-color);
    height: 70px;
    transition: all 0.5s;
  }

  #logo img {
    max-height: 40px;
  }
}
