@import "../../assets/scss/main.scss";

.navbar {
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  li {
    position: relative;
  }

  > ul > li {
    white-space: nowrap;
    padding: 10px 0 10px 24px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    padding: 0 2px;
    white-space: nowrap;
    transition: 0.3s;
    letter-spacing: 0.4px;
    position: relative;

    &:focus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(255, 255, 255, 0.8);
      font-size: 15px;
      padding: 0 2px;
      white-space: nowrap;
      transition: 0.3s;
      letter-spacing: 0.4px;
      position: relative;
    }

    i,
    &:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }

    &:hover:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  > ul > li > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -8px;
    left: 0;
    background-color: $white;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  li:hover > a:before,
  .active:before {
    visibility: visible;
    transform: scaleX(1);
  }

  a:hover {
    color: $white;
  }

  .active {
    color: $white;

    &:focus {
      color: $white;
    }
  }

  li:hover > a {
    color: $white;
  }

  .dropdown {
    ul {
      display: block;
      position: absolute;
      left: 24px;
      top: calc(100% + 30px);
      margin: 0;
      padding: 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: $white;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      border-radius: 4px;

      li {
        min-width: 200px;
      }

      a {
        padding: 10px 20px;
        font-size: 14px;
        text-transform: none;
        color: $night-rider;

        i {
          font-size: 12px;
        }

        &:hover {
          color: $second-color;
        }
      }

      .active:hover,
      li:hover > a {
        color: $second-color;
      }
    }

    &:hover > ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }

    .dropdown {
      ul {
        top: 0;
        left: calc(100% - 30px);
        visibility: hidden;
      }

      &:hover > ul {
        opacity: 1;
        top: 0;
        left: 100%;
        visibility: visible;
      }
    }
  }
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown {
    ul {
      left: -90%;
    }

    &:hover > ul {
      left: -100%;
    }
  }
}

.mobile-nav-toggle {
  color: $white;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: $first-color;
  transition: 0.3s;
  z-index: 999;

  .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: $white;
    overflow-y: auto;
    transition: 0.3s;
  }

  > ul > li {
    padding: 0;
  }

  a:hover:before,
  li:hover > a:before,
  .active:before {
    visibility: hidden;
  }

  a {
    padding: 10px 20px;
    font-size: 15px;
    color: $night-rider;

    &:focus {
      padding: 10px 20px;
      font-size: 15px;
      color: $night-rider;
    }

    &:hover {
      color: $second-color;
    }
  }

  .active,
  li:hover > a {
    color: $second-color;
  }

  .getstarted {
    margin: 15px;

    &:focus {
      margin: 15px;
    }
  }

  .dropdown {
    ul {
      position: static;
      display: none;
      margin: 10px 20px;
      padding: 10px 0;
      z-index: 99;
      opacity: 1;
      visibility: visible;
      background: $white;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);

      li {
        min-width: 200px;
      }

      a {
        padding: 10px 20px;

        i {
          font-size: 12px;
        }

        &:hover {
          color: $second-color;
        }
      }

      .active:hover,
      li:hover > a {
        color: $second-color;
      }
    }

    > .dropdown-active {
      display: block;
    }
  }
}
