@import "../../../../assets/scss/main.scss";

#features {
  background: $white;
  padding: 60px 0 0 0;
  overflow: hidden;

  .section-title {
    text-align: center;
    margin-bottom: 25px;
  }

  .features-img {
    text-align: center;
    padding-top: 20px;

    img {
      max-width: 100%;
    }
  }

  .box {
    margin-bottom: 15px;
    text-align: center;
  }

  .icon {
    margin-bottom: 10px;

    img {
      width: 40px;
      transition: 0.5s;
    }
  }

  .title {
    font-weight: 300;
    margin-bottom: 15px;
    font-size: 22px;

    span {
      color: $black-2;
    }
  }

  .description {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: 100;
    color: $grey-3;
  }

  .section-description {
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  #features {
    .features-img {
      text-align: center;
      padding: 20px 0px 20px 0px;

      img {
        max-width: 75%;
      }
    }
  }
}
